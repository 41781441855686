.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-content {
  flex: 1 0 auto;
}

