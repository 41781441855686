.navbar {
    border-bottom: 10px solid #000;
}

.nav-links {
    gap: 2rem;
}

.active {
    color: #00cff9 !important;
    border-bottom: 1px solid #00cff9 !important;
}

#logo-nav {
    height: 100px;
    width: 215px;
}

#logo-nav-sm {
    display: none;
    height: 80px;
}

@media (max-width: 992px) {
    .nav-links {
        gap: .5rem;
    }
    .active {
        border: none !important;
    }
}