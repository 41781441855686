.about-image {
    height: 100vh;
    width: 100%;
    min-width: 50%;
    object-fit: cover;
    object-position: 50% 50%;
}

@media (max-width: 576px) {
    .about-container {
        flex-direction: column;
    }

}