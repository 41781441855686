.main-section {
    min-height: 100vh;
}

.section-image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.content {
    color: #fff;
}

.contact-btn {
    background-color: #00cff9 !important;
    color: #fff !important;
    padding: 8px 28px;
    border: none;
    border-radius: 3px;
}