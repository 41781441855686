footer {
    margin: 0;
    background-color: #000;
    color: #fff;
    flex-shrink: 0;
}

footer a {
    color: #00cff9 !important;
}

.footer-top {
    align-items: center;
}

.copyright {
    font-size: .85rem;
}

.contact {
    list-style: none;
}

#logo-footer {
    height: 100px;
    width: 215px;
}

hr {
    margin: 0 !important;
}

@media (max-width: 576px) {
    .footer-top {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    
    .contact {
        padding-left: 10px;
    }
}